<template>
 <div class="bring-buddy">
     <div class="nav-banner-wrapper">
     <Navbar/>
    <BringBuddyBanner/>
     </div>
    <ReferralBonus/>
    <ReferralApplication/>
    <ReferralProcess/>
    <Footer/>
 </div>
</template>

<script>
import Navbar from "../Talent/Home/components/navbar/navbar.vue"
import ReferralApplication from "./components/referralApplication/referralApplication.vue"
import BringBuddyBanner from "./components/bringBuddyBanner/bringBuddyBanner.vue"
import ReferralProcess from "./components/referralProcess/referralProcess.vue"
import ReferralBonus from "./components/referralBonus/referralBonus.vue"
import Footer from "../Talent/Home/components/footer/footer.vue"
import "./bringBuddy.scss"
export default {
  components: {Navbar,BringBuddyBanner,ReferralBonus,ReferralProcess,ReferralApplication,Footer},
};
</script>

<style lang="scss" scoped></style>