<template>
  <div class="referral-card">
    <h3>{{amount}}</h3>
    <p class="referral-card-type">{{type}}</p>
    <p class="referral-type-position">{{position}}</p>
    <p class="referral-type-years">{{years}}</p>
  </div>
</template>

<script>
import "./referralCard.scss";
export default {
    props:["amount","type","position","years"],
  components: {},
};
</script>

<style lang="scss" scoped></style>
