<template>
  <div class="referral-bonus">
    <div class="container text-center">
   <div class="heading">
      <h2>
      {{ $t('bringBuddy.referral') }} <span>{{ $t('bringBuddy.bonus') }}</span>
    </h2>
    <div class="d-flex align-items-center justify-content-center mt-3">
      <img src="../../../../assets/images/ic_line-title.svg" />
    </div>
   </div>
   <div class="row justify-content-center">
     <div class="col-xl-4 col-md-6"  v-for="(referral, i) in referalCardsData"
              :key="i">
       <ReferralCard :amount="$t(`bringBuddy.${[referral.amount]}`) " :type=" $t(`bringBuddy.${[referral.type]}`)" :position="referral.position?  $t(`bringBuddy.${[referral.position]}`) : ''" :years="$t(`bringBuddy.${[referral.years]}`)"/>
     </div>
   </div>
    </div>
  </div>
</template>

<script>
import ReferralCard from "../referralCard/referralCard.vue"
import "./referralBonus.scss"
export default {
  components: {ReferralCard},
  data: () => {
    return{
       referalCardsData:[
         {
           amount:"500Usd",
           type:"managerialReferral",
           position:"managerCTODirector",
           years: "+10Years"
       },
       {
         amount:"450Usd",
           type:"techLeadReferral",
           position:"",
           years: "7-12Years"
       },
       {
         amount:"300Usd",
           type:"seniorReferral",
           position:"",
           years:"5-7Years"
       },
       {
           amount:"200Usd",
           type:"midSeniorReferral",
           years:"2-4Years"
       },
       {
         amount:"100Usd",
           type:"juniorReferral",
           position:"",
           years:"0-2Years"
       }
       ]
    }
  }
};
</script>

<style lang="scss" scoped></style>