<template>
  <div class="talent-home-navbar">
    <div class="container px-0 z-index-1 position-relative">
      <nav class="navbar navbar-expand-xl">
        <a class="navbar-brand" @click="handleRedirection('/home/talent')">
          <img
            src="../../../../../assets/images/logo/2Asset 1.svg"
            alt=""
            width="120"
        /></a>
        <div
          v-if="this.user.first_name"
          class="btn-group login-btn loggedin-dropdown"
          style="order: 2"
        >
          <button
            type="button"
            class="btn dropdown-toggle color-white logedin-talent-nav-user"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ truncate(this.user.first_name, 12) }}
          </button>
          <ul :class="['dropdown-menu dropdown-menu-end logout-menu']">
            <button class="dropdown-item" type="button" @click="goToProfile()">
              <i class="bi bi-person me-2"></i>
              {{ $t("home.myProfile") }}
            </button>
             <button v-if="isTalenttHasContractRenewal" class="dropdown-item" type="button" @click="goToContractRenewal()">
              <i class="bi bi-book me-2"></i>
             {{ $t("talent.contractRenewal") }}
            </button>
           <!--  <button class="dropdown-item" type="button" @click="setLocale($i18n.locale)">
              <i class="bi bi-person me-2"></i>
              {{ $i18n.locale == "en" ? "عربي" : "English" }}
            </button> -->
            <li>
              <button class="dropdown-item" type="button" @click="logOut">
                <i class="bi bi-box-arrow-right me-2"></i>
                {{ $t("home.logout") }}
              </button>
            </li>

          </ul>
        </div>
        <div class="login-btn btn-group loggedin-dropdown" style="order: 2" v-else @click="redirectToLogin">
          <button type="button" class="ml-auto text-white btn login">
            {{ $t("home.login") }}
          </button>
<!--           <LanguageSwitcher /> -->
        </div>
      
        <LanguageSwitcher separator-light has-divider divider-at-start class="me-2 ms-auto" />
        <button
          class="navbar-toggler light"
          type="button"
          @click="triggerMobileMenu = !triggerMobileMenu"
        >
          <!-- <span class="navbar-toggler-icon"> -->
          <svg data-v-0315f6de="" width="20px" height="23px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g data-v-0315f6de="" id="Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0315f6de="" id="02.01.squadio2-final-design" transform="translate(-1462.000000, -39.000000)"><g data-v-0315f6de="" id="Group-5" transform="translate(1462.000000, 39.000000)"><rect data-v-0315f6de="" id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="8" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="16" width="20" height="2" rx="1"></rect></g></g></g></svg>
          <!-- </span> -->
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" >
          <ul
            class="mb-2 nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
          >
            <li>
              <router-link
                to="/home/talent"
                :class="
                  isBringBuddy || isCodeChallenge
                    ? 'nav-link px-3'
                    : 'nav-link px-3 link-secondary'
                "
              >
                {{ $t("talent.talentHome") }}
              </router-link>
            </li>
            <li>
              <router-link
                to='/bring-buddy'
                :class="
                  isBringBuddy
                    ? 'nav-link px-3 link-secondary'
                    : 'nav-link px-3 '
                "
              >
                {{ $t("talent.bringYourBuddyAgain") }}
              </router-link>
            </li>
            <li v-if="isTalenttHasContractRenewal">
              <router-link
               to="/sq/talent/contracts-renewal"
                :class="$route.path.includes('renewal') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "
              >
                 {{ $t("talent.contractRenewal") }}
              </router-link>
            </li>
            
            <!-- <li><a @click="handleRedirection('/code-challenge')" 
            :class="isCodeChallenge?'nav-link px-3 link-secondary':'nav-link px-3 '"
            >Coding Challenge</a></li> -->
          </ul>
          <ApplyViewBtn class="px-1" />

          <LanguageSwitcher  class="language-switcher-navbar" />

        </div>
        <mobile-menu
          :is-open="triggerMobileMenu"
          :logged-in-talent="!!this.user.first_name"
          :apply-as-talent="!this.user.first_name"
          @close-menu="menuClosed"
        >
          <li class="mobileMenu__item">
            <router-link
              to="/"
              :class="!$route.path.includes('renewal') && !$route.path.includes('buddy')  ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "

            >
              {{ $t("talent.talentHome") }}
            </router-link>
          </li>
          <li class="mobileMenu__item">
            <router-link
              to="/bring-buddy"
             :class="$route.path.includes('bring-buddy') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "
            >
              {{ $t("talent.bringYourBuddyAgain") }}
            </router-link>
          </li>
           <li class="mobileMenu__item" v-if="isTalenttHasContractRenewal">
              <router-link
               to="/sq/talent/contracts-renewal"
                :class="$route.path.includes('renewal') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "
              >
                 {{ $t("talent.contractRenewal") }}
              </router-link>
            </li>
        </mobile-menu>
      </nav>
    </div>
  </div>
</template>
<script>
import "./navbar.scss";
import { mapActions, mapGetters } from "vuex";
import { axiosInstance } from "@/network/interceptors.js";

import { truncate } from "../../../../../utils/utils";
import Steps from "../../../../../enums/WizzardSteps";
import ApplyViewBtn from "../applyViewBtn/applyViewBtn.vue";
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import MobileMenu from "@/components/MobileMenu";


export default {
  components: {
    ApplyViewBtn,
    LanguageSwitcher,
    MobileMenu,
  },
  data() {
    return {
      triggerMobileMenu: false,
      isBringBuddy: window.location.href.includes("buddy"),
      isCodeChallenge: window.location.href.includes("challenge"),
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isTalenttHasContractRenewal: "auth/getTalentHasContractRenewal"
    }),
  },
  mounted() {
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
    this.setTalenttHasContractRenewal()
  },
  methods: {
     ...mapActions({
      setTalenttHasContractRenewal: "auth/setTalentHasContractRenewal"
    }),
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    truncate,
    handleRedirection(path) {
      this.$router.push({ path });
    },
    goToProfile() {
      if (this.user.step == Steps.PROFILE) {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ name: "Wizard" });
      }
    },
    goToContractRenewal() {
      this.$router.push('/sq/talent/contracts-renewal');
    },
    logOut() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/home/talent" });
      });
    },
    setLocale(locale) {
      this.$i18n.locale = locale == "en" ? "ar" : "en";
      localStorage.setItem("lang", this.$i18n.locale);
      axiosInstance.defaults.headers.common["Accept-Language"] = `${this.$i18n.locale}`;

    },
    redirectToLogin() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.language-switcher-navbar {
  padding: 14px 20px;
  @media (max-width: 576px) {
    margin-top: 20px;
  }
}
</style>