import { render, staticRenderFns } from "./bringBuddyBanner.vue?vue&type=template&id=2c6e063a&scoped=true"
import script from "./bringBuddyBanner.vue?vue&type=script&lang=js"
export * from "./bringBuddyBanner.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6e063a",
  null
  
)

export default component.exports